@keyframes spin {
  100% {
    transform: rotateY(360deg);
  }
}

.loaderContainer {
  position: fixed !important;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;

  .linearLoader {
    .MuiLinearProgress-bar {
      background-color: #86288E !important;
    }
  }

  .circularLoader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;

    img { /* Dodajte specifičnu klasu za sliku */
      width: 150px;
      height: 150px;
      animation:rotation 1.8s infinite linear;
    }

    @keyframes rotation {100%{ transform:rotatey(360deg); }}
  }

  .overlayContent{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .spinner {
      width: 75px;
      height: 75px;
      display: inline-block;
      border-width: 2px;
      border-color: rgba(255, 255, 255, 0.05);
      border-top-color: var(--white-color);
      animation: spin 1s infinite linear;
      border-radius: 100%;
      border-style: solid;
    }
  }
}