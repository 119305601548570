header {
  .headerMainContainer {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999999999;
    background-color: var(--crux-beige);
    color: #F7F4ED;
  }
  .navItems{
    ul {
      flex-direction: row;
      li {
        &:not(:last-child){
          margin-right: 20px;
        }
      }
    }
  }
  .headerContainer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 15px;
    background-color: #F7F4ED;
    .headerLogo{
      position: relative;
      left: 10px;
      .logoLink{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .headerItems {
      display: flex;
      align-items: center;
      justify-content: space-between;
      nav {
        ul {
          display: flex;
          li {
            list-style-type: none;
            &:not(:last-child){
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

.bgWhite {
  background-color: white !important;
}